import request from "@/utils/request";
 /**
 * 订单头部统计
 * @param query
 * @returns {AxiosPromise}
 */
export const orderHeader = () => {
    return request({
        url: '/sys/count/selectSumTradingToday',
        method: 'get'
    });
};
/**
 * 订单饼状图
 * @param type
 * @returns {AxiosPromise}
 */
export const orderLeft = (param) => {
    return request({
        url: '/sys/count/selectSumTrading',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 课时头部统计
 * @param query
 * @returns {AxiosPromise}
 */
export const courseHeaderSummary = () => {
    return request({
        url: '/sys/count/selectCourseCount',
        method: 'get'
    });
};
/**
 * 课时饼状图
 * @param query
 * @returns {AxiosPromise}
 */
export const courseBreadSummary = (param) => {
    return request({
        url: '/sys/count/selectSumCourse',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 课时柱状图
 * @param query
 * @returns {AxiosPromise}
 */
export const courseHistogramSummary = (list) => {
    return request({
        url: '/sys/count/courseTreeView',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 教练课时柱状图
 * @param query
 * @returns {AxiosPromise}
 */
export const coachCourseStatistical = (list) => {
    return request({
        url: '/sys/count/courseCoachRanking',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 缴费记录柱状图
 * @param query
 * @returns {AxiosPromise}
 */
export const CoachPerformanceHistogramSummary = (list) => {
    return request({
        url: '/sys/count/selectCoachPerformance',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//web扫码记录列表 
export const pageScanLogList = (list) => {
    return request({
        url: '/sys/qrcodescann/pageScanLogList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

<template>
    <div style="padding:40px">
        <div style="margin-bottom:10px;display:flex;align-items: center;">
            <el-input v-model="query.keyword" class="inPut" placeholder="请输入提交姓名"></el-input>
            <el-cascader
                v-model="areaList"
                class="inPut"
                placeholder="请选择区域"
                :options="treeList"
                collapse-tags 
                :collapse-tags-tooltip="true"
                @change="handleChange"
                clearable
                :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
            <el-date-picker
                v-model="v1"
                style="margin:  10px"
                type="daterange"
                range-separator="至"
                @change="disposeTime"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :clearable="false"
                    >
            </el-date-picker>
            <el-button type="primary" class="inPut" @click="getData()">搜索</el-button>
        </div>
        <el-table :data="ScanLogList" border row-style="{height:2+'px'}"
                :cell-style="{fontSize:'12.5px',padding:'17px 0px'}"
                :header-cell-style="{background:'#ffff !important;'}">
            <el-table-column label="提交姓名" prop="submitName"/>
            <el-table-column label="提交手机号">
                <template #default="scope">
                    <div :style="scope.row.authPhone != scope.row.submitPhone ? 'color:red' : ''">{{ scope.row.submitPhone }}</div>
                </template>
            </el-table-column>
            <el-table-column label="登录手机号" prop="authPhone"/>
            <el-table-column label="推广教练" prop="sourceName"/>
            <el-table-column label="中招/贝体" prop="areaType"/>
            <el-table-column label="提报区域" width="200">
                <template #default="scope">
                    {{ scope.row.campusCity + '--' + scope.row.campusArea + '--' + scope.row.campusName }}
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime"/>
            <el-table-column label="备注" prop="remark"/>
        </el-table>
        <div class="pagination">
            <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="current"
            :total="total"
            @current-change="handlePageChange"
            ></el-pagination> 
        </div>
    </div>
</template>
<script>
import {pageScanLogList} from '@/api/count'
import {listAreaTreeAddCoach} from "@/api/area.js";
    export default{ 
        data(){
            return{
                v1:[],
                areaList:[],
                query:{
                    areaList: [],
                    endDate:'',
                    keyword:'',
                    pageIndex: 1,
                    pageSize: 10,
                    startDate:''
                },
                ScanLogList:[],
                total:null,
                current:1,
                treeList:[],
            }
        },
        methods:{
            getData(){ 
                pageScanLogList(this.query).then(res=>{ 
                    if(res.code == 200){ 
                        this.ScanLogList = res.data.scanLog
                        this.total = res.data.dataCount
                    }else{ 
                        this.$message.error('请求数据失败！')
                    }
                })
            },
            handlePageChange(e){ 
                this.query.pageIndex = e
                this.getData()
            },
            getTypeList(commodityType) {
                if (commodityType!=null){
                    commodityType.forEach(items => {
                    if (items!=null){
                        if (items.children!=null && items.children.length > 0) {
                        this.getTypeList(items.children);
                        } else {
                        items.children = undefined;
                        }
                    }
                    });
                    return commodityType;
                }
            },
            disposeTime(){
                if(this.v1.length>=2){
                this.query.startDate = this.dateFormat(this.v1[0]);
                this.query.endDate = this.dateFormat(this.v1[1]);
                }
            },
            dateFormat(val) {
                var date = new Date(val);
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                var d = date.getDate();
                d = d < 10 ? "0" + d : d;
                const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
                return time;
            },
            handleChange(val){ 
                let areaListId = [];
                val.forEach(function(item){
                    areaListId.push(...item)
                })
                this.query.areaList = areaListId
            }
        },
        created(){
            this.getData(),
            listAreaTreeAddCoach().then(val => {
                if (val.code==200){    
                this.treeList =  this.getTypeList(val.data);
                }else{ 
                    this.$message.error('请求数据失败！')
                }
            })
        }
    }
</script>
<style scoped>
.inPut{
    margin: 0 10px;
    width:260px;
}
</style>
